import {
	Menu,
	MenuItem
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../common/components/Button';
import '../styles/_actions.scss';
import {
	faBullhorn,
	faCheck, faCommentAlt, faEdit,
	faEllipsisV, faShare,
	faUsers
} from '@fortawesome/free-solid-svg-icons';
import { faJira } from '@fortawesome/free-brands-svg-icons';
import NudgeButton from './NudgeButton';

export default function Actions({ onShare, onAnnounce, onTask, onNudge, IAmHost, onOpenTeam, isMobile, onOpenChat, members = [] }) {
	if (isMobile) {
		const everyoneEstimated = Boolean(members.length && members.every(member => member.estimate));
		
		return (
			<>
				<button className="actions--mobile__btn">
					<FontAwesomeIcon icon={faUsers} onClick={onOpenTeam} id="open-team-mobile-btn" />
					{everyoneEstimated && <FontAwesomeIcon id="open-team-tick-mobile-btn" icon={faCheck} onClick={onOpenTeam} className="actions--mobile__btn-tick" />}
				</button>
				<button className="actions--mobile__btn">
					<FontAwesomeIcon icon={faCommentAlt} onClick={onOpenChat} id="open-chat-mobile-btn" />
				</button>
				<Menu
					offsetY={10}
					menuButton={(
						<button className="actions--mobile__btn">
							<FontAwesomeIcon icon={faEllipsisV} />
						</button>
					)}
					transition
				>
					{onShare && <MenuItem onClick={onShare}><FontAwesomeIcon className="mr-20" icon={faShare} />Share Session</MenuItem>}
					{/*{IAmHost && <MenuItem><FontAwesomeIcon className="mr-20" icon={faEdit} />Change This Session</MenuItem>}*/}
					{onAnnounce && IAmHost && <MenuItem onClick={onAnnounce}><FontAwesomeIcon className="mr-20" icon={faBullhorn} />Announce Message</MenuItem>}
					{onTask && IAmHost && <MenuItem onClick={onTask}><FontAwesomeIcon className="mr-20" icon={faJira} />Add Estimate To Jira</MenuItem>}
				</Menu>
			</>
		);
	}
	
	return (
		<div className="actions">
			{onShare && <Button id="share-btn" tooltipPlace="bottom" onClick={onShare} icon="faShare" tooltip="Share this session" tooltipId="share-meeting" />}
			{/*{IAmHost && <Button id="edit-btn" tooltipPlace="bottom" icon="faEdit" tooltip="Change this session" tooltipId="change" />}*/}
			{onNudge && IAmHost && <NudgeButton minimised onNudge={onNudge} />}
			{onAnnounce && IAmHost && <Button id="announce-btn" tooltipPlace="bottom" onClick={onAnnounce} icon="faBullhorn" tooltip="Announce a message to the team" tooltipId="announce" />}
			{onTask && IAmHost && <Button id="jira-task-btn" tooltipPlace="bottom" onClick={onTask} icon="faJira" tooltip="Add estimate to Jira" tooltipId="task" />}
		</div>
	);
}