import HANDS from '../../../assets/images/hands';

export default function Hands({ value, small, aspectRatio = 'xMinYMin meet' }) {
	const normalisedValue = value ? (value > 10 ? 11 : value) : 0;
	const LeftHand = HANDS[`L${normalisedValue <= 5 ? normalisedValue : (normalisedValue === 11 ? 6 : 5)}`];
	const RightHand = normalisedValue <= 5 ? HANDS.R0 : HANDS[`R${normalisedValue === 11 ? 6 : normalisedValue - 5}`];
	
	return (
		<div className="meeting__hands">
			<RightHand
				preserveAspectRatio="xMaxYMin meet"
				height={small ? 90: 200}
				width={small ? 90 : 200}
			/>
			<LeftHand preserveAspectRatio={aspectRatio} height={small ? 90: 200} width={small ? 90 : 200} />
		</div>
	);
}