import { useState } from "react";
import Noty from 'noty';
import axios from 'axios';
import CONFIG from '../config';
import { ReactComponent as Creation } from '../assets/images/creation.svg';
import Input from '../common/components/Input';
import Button from '../common/components/Button';
import './styles/_new-meeting.scss';
import { InfoTooltip, Text } from '../common/components';
import postEvent from '../common/utils/postEvent';
import Toggle from '../common/components/Toggle';

const FIBO_LINK = 'https://www.productplan.com/glossary/fibonacci-agile-estimation/#:~:text=The%20Fibonacci%20sequence%20is%20one,for%20estimating%20agile%20story%20points.&text=The%20Fibonacci%20sequence%20goes%20as,effort%20of%20agile%20development%20tasks.';

export default function NewMeeting({ onBack, setNewMeeting, uid, name: hostName }) {
    const [name, setName] = useState('');
    const [saving, setSaving] = useState(false);
    const [useFibonacci, setFibonacci] = useState(false);
    const [useInfinite, setInfinite] = useState(false);

    const onChangeName = ({ target: { value } }) => {
        setName(value);
    };

    const onCreateMeeting = async () => {
        try {
            setSaving(true);

            const meeting = await axios.post(`${CONFIG.apiUrl}/meeting/create`, {
                host: uid,
                name: name,
                hostName,
                useFibonacci,
                useInfinite
            });
    
            postEvent('create-meeting');
    
            setNewMeeting(meeting.data);
        } catch(e) {
            setSaving(false);

            new Noty({
                theme: 'metroui',
                text: 'Oops. Something went wrong.',
                type: 'error',
                timeout: 3000,
                layout: 'bottomRight'
            }).show();
        }
    };

    return (
      <>
          <Creation />
          <div className="new-meeting__content">
              <div className="new-meeting__content__block">
                  <div className="new-meeting__content__block__step"><Text subHeading>Step</Text><span>1</span></div>
                  <Text className="mb-40">Choose a descriptive session name.</Text>
                  <Input
                      placeholder="Session name"
                      onChange={onChangeName}
                      value={name}
                      onEnter={name ? onCreateMeeting : void 0}
                  />
              </div>
        
              <div className="new-meeting__content__block">
                  <div className="new-meeting__content__block__step"><Text subHeading>Step</Text><span>2</span></div>
                  <Text className="mb-40">Choose how your team can estimate.</Text>
                  <Toggle
                    label={<>Only <a className="ml-5 mr-10" rel="noreferrer" href={FIBO_LINK} target="_blank">Fibonacci estimation</a><InfoTooltip className="ml-auto" id="fibo-tooltip" text="Users will only be able estimate using numbers from the Fibonacci sequence." /></>}
                    defaultChecked={useFibonacci}
                    onChange={setFibonacci}
                  />
                  <Toggle
                    className="mt-20"
                    label={<><span className="mr-10">Allow estimates higher than 10 </span><InfoTooltip id="infinite-estimate-tooltip" text="This will turn off hand visualisations for estimates greater than 10." className="ml-auto" /></>}
                    defaultChecked={useInfinite}
                    onChange={setInfinite}
                  />
              </div>
    
              <Button id="create-session-save-btn" disabled={!name} busy={saving} fullWidth onClick={onCreateMeeting}>
                  Create Session
              </Button>
          </div>
      </>
    );
}