import Button from '../../../common/components/Button';
import Input from '../../../common/components/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { faArrowCircleDown, faArrowCircleUp, faLock } from '@fortawesome/free-solid-svg-icons';
import Hands from './Hands';
import '../styles/_estimate.scss';

const FIBO_LINK = 'https://www.productplan.com/glossary/fibonacci-agile-estimation/#:~:text=The%20Fibonacci%20sequence%20is%20one,for%20estimating%20agile%20story%20points.&text=The%20Fibonacci%20sequence%20goes%20as,effort%20of%20agile%20development%20tasks.';

const QUICK_FIBO_VALUES = [1, 2, 3, 5, 8];
const EXTENDED_QUICK_FIBO_VALUES = [...QUICK_FIBO_VALUES, 13, 21];

const FIBO_VALUES = [
	...QUICK_FIBO_VALUES,
	13, 21, 34, 55, 89, 144, 233, 377, 610, 987
];

export default function Estimate({ onChangeEstimate, estimate, locked, useFibonacci, useInfinite }) {
	const MAX_ESTIMATE = useInfinite ? 999 : 10;
	
	const handleEstimateChange = ({ target: { value } }) => {
		const parsed = value ? parseInt(value, 10) : 0;
		
		if (!parsed) { return void onChangeEstimate(parsed); }
		
		if (useFibonacci && !FIBO_VALUES.includes(parsed)) { return; }
		
		onChangeEstimate(parsed > MAX_ESTIMATE ? MAX_ESTIMATE : parsed);
	};
	
	const handleEstimateUp = () => {
		if (!useFibonacci) {
			return void onChangeEstimate(estimate + 1);
		}
		
		const currentFiboIndex = FIBO_VALUES.findIndex(value => value === estimate);
		
		onChangeEstimate(FIBO_VALUES[currentFiboIndex + 1]);
	};
	
	const handleEstimateDown = () => {
		if (!useFibonacci) {
			return void onChangeEstimate(estimate - 1);
		}
		
		const currentFiboIndex = FIBO_VALUES.findIndex(value => value === estimate);
		
		onChangeEstimate(FIBO_VALUES[currentFiboIndex - 1] || 0);
	};
	
	const fiboValuesToUse = useInfinite ? FIBO_VALUES : QUICK_FIBO_VALUES;
	
	const upEnabled = (useInfinite && !useFibonacci) || (
			useFibonacci ? estimate < fiboValuesToUse[fiboValuesToUse.length - 1] : estimate < MAX_ESTIMATE
	);
	
	return (
		<div className="estimate">
			<div className="estimate__entry">
				<div className={cx('estimate__entry__lock', { 'estimate__entry__lock--visible': locked })}>
					<FontAwesomeIcon icon={faLock} />
				</div>
				<p className="estimate__entry__help">
					Choose your task estimate below. <strong>{useFibonacci ? 'You\'re using Fibonacci estimates' : <>We recommend estimates follow the <a rel="noreferrer" href={FIBO_LINK} target="_blank">fibonacci sequence</a></>}</strong>.
				</p>
				<div className={cx('estimate__entry__quick-btns', { 'estimate__entry__quick-btns--extended': useInfinite })}>
					{(useInfinite ? EXTENDED_QUICK_FIBO_VALUES : QUICK_FIBO_VALUES).map(value => (
						<Button key={value} id={`quick-estimate-${value}-btn`} onClick={() => onChangeEstimate(value)}>{value}</Button>
					))}
				</div>
				<div className="estimate__entry__input">
					<Input onChange={handleEstimateChange} disabled={useInfinite && useFibonacci} value={estimate || ''} placeholder="My estimate"/>
					<FontAwesomeIcon className={cx('estimate__entry__input__arrow', { 'estimate__entry__input__arrow--disabled': !upEnabled })} id="quick-estimate-up-btn" onClick={upEnabled ? handleEstimateUp : void 0} icon={faArrowCircleUp} color="" />
					<FontAwesomeIcon className={cx('estimate__entry__input__arrow', { 'estimate__entry__input__arrow--disabled': !estimate })} id="quick-estimate-down-btn" onClick={estimate ? handleEstimateDown : void 0} icon={faArrowCircleDown} color="" />
				</div>
			</div>
			<Hands value={estimate} />
		</div>
	);
}