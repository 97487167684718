import queryString from 'querystring';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Route, Switch, useHistory, useLocation, withRouter } from 'react-router-dom';
import Name from './components/Name';
import Meeting from './components/Meeting';
import NewMeeting from './components/NewMeeting';
import Landing from './components/Landing';
import CoreLayout from './components/CoreLayout';

const LOCATION_PROPS_MAP = onBack => ({
  '/': { title: 'Let\'s Get Started', className: 'landing' },
  '/meeting/new': { title: 'Create A New Session', className: 'new-meeting', onBack },
  '/meeting/new/': { title: 'Create A New Session', className: 'new-meeting', onBack },
  'default': {
    className: 'meeting',
    onBack,
    headerNewFeatureMobileOnly: true,
    headerNewFeatureProps: {
      id: 'team-comments',
      title: 'Introducing Team Comments',
      text: 'Have conversations with your team mates in the session. Leave replies to specific comments and build comment threads. Hosts can still send announcements to the whole team.'
    }
  }
});

const ScrollToTop = withRouter(({ history }) => {
  useEffect(() =>
    history.listen(() => {
      window.scrollTo(0, 0);
    }),
    []
  );
  
  return null;
});

function getIdentity() {
  return JSON.parse(localStorage.getItem('identity') || '{}');
}

function App() {
  // -- Handle Legacy -- //
  const legacyName = localStorage.getItem('name');
  const legacyUid = localStorage.getItem('uid');
  
  if (legacyName && legacyUid) {
    localStorage.setItem('identity', JSON.stringify({ name: legacyName, uid: legacyUid }));
    localStorage.removeItem('name');
    localStorage.removeItem('uid')
  }
  
  const identity = getIdentity();
  const [name, setName] = useState(identity.name);
  const [uid, setUid] = useState(identity.uid);
  const [meeting, setMeeting] = useState();
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(window.location.search);
  
  if (query.state) {
    history.push(`/meeting/${query.state}?jira_code=${query['?code']}`);
  }
  
  const onCreateMeeting = () => {
    history.push('/meeting/new');
  };

  const onBack = () => {
    setMeeting();
    history.push('/');
  };

  const onSetName = (name) => {
    const uid = uuid();

    setName(name);
    setUid(uid);

    localStorage.setItem('identity', JSON.stringify({ name, uid }));
  };

  const handleMeeting = (meeting) => {
    setMeeting(meeting);
    history.push(`/meeting/${meeting.id}`);
  }

  const handleJoinMeeting = meetingId => {
    history.push(`/meeting/${meetingId}`);
  }

  if (!name) { return <Name onContinue={onSetName} />; }
  
  const coreLayoutProps = LOCATION_PROPS_MAP(onBack);
  
  return (
    <CoreLayout {...(coreLayoutProps[location.pathname] || coreLayoutProps.default)}>
      <ScrollToTop />
      <Switch>
        <Route exact path="/meeting/new" render={() => <NewMeeting setNewMeeting={handleMeeting} name={name} uid={uid} />} />
        <Route exact path="/meeting/:meetingId" render={() => <Meeting meeting={meeting} uid={uid} name={name} />} />
        <Route path="/" render={() => <Landing onMeetingId={handleJoinMeeting} onCreateMeeting={onCreateMeeting} />} />
      </Switch>
    </CoreLayout>
  );
}

export default App;
