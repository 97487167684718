import { ReactComponent as L0 } from './left-0.svg';
import { ReactComponent as L1 } from './left-1.svg';
import { ReactComponent as L2 } from './left-2.svg';
import { ReactComponent as L3 } from './left-3.svg';
import { ReactComponent as L4 } from './left-4.svg';
import { ReactComponent as L5 } from './left-5.svg';
import { ReactComponent as L6 } from './left-6.svg';
import { ReactComponent as R0 } from './right-0.svg';
import { ReactComponent as R1 } from './right-1.svg';
import { ReactComponent as R2 } from './right-2.svg';
import { ReactComponent as R3 } from './right-3.svg';
import { ReactComponent as R4 } from './right-4.svg';
import { ReactComponent as R5 } from './right-5.svg';
import { ReactComponent as R6 } from './right-6.svg';

const HANDS = {
    L0,
    L1,
    L2,
    L3,
    L4,
    L5,
    L6,
    R0,
    R1,
    R2,
    R3,
    R4,
    R5,
    R6
};

export default HANDS;
