import { Button, Input } from '../../../common/components';
import Modal from './Modal';
import { useState } from 'react';
import '../styles/_announcement-modal.scss';

export default function AnnouncementModal({ visible, onClose, onSave, announcement }) {
	const [clicked, setClicked] = useState(false);
	const [message, setMessage] = useState('');
	
	const onMessageChange = ({ target: { value } }) => {
		setMessage(value);
	};
	
	const onClickSave = () => {
		setClicked(true);
		onSave(message);
	}
	
	const onStateEnd = () => {
		setClicked(false);
		setMessage('');
	}
	
	return (
		<Modal
			className="announcement-modal"
			title={announcement ? 'Announcement' : 'Announce A Message'}
			visible={visible}
			onClose={onClose}
		>
			{
				announcement
					? <p className="announcement-modal__message">{announcement}</p>
					: (
						<>
							<Input
								multiline
								placeholder="eg. Name of the task you're estimating"
								onChange={onMessageChange}
								disabled={clicked}
								value={message}
							/>
							<Button
								id="announcement-save-btn"
								className="announcement-modal__save"
								disabled={!message}
								onClick={onClickSave}
								state={clicked ? 'SUCCESS' : void 0}
								onStateEnd={onStateEnd}
							>
								Send To Team
							</Button>
						</>
					)
			}
		</Modal>
	);
}