import { ReactComponent as NotFoundImage } from '../../../assets/images/meeting-doesnt-exist.svg';
import { ReactComponent as ErrorImage } from '../../../assets/images/error.svg';
import { Text } from '../../../common/components';
import '../styles/_error.scss';

export default function Error({ notFound }) {
	const Image = notFound ? NotFoundImage : ErrorImage;
	const title = notFound ? 'We can\'t seem to find that session.' : 'Oops, something went wrong.';
	const subtitle = notFound ? 'Make sure your session code is correct.' : 'Try reloading the page';
	
	return (
		<div className="error">
			<Image />
			<div className="error__text">
				<Text heading>{title}</Text>
				<Text className="mt-20">{subtitle}</Text>
			</div>
		</div>
	)
}