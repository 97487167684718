import { useEffect, useState } from 'react';
import Button from '../../../common/components/Button';
import { ReactComponent as Time } from '../../../assets/images/time.svg';
import '../styles/_countdown.scss';
import { Text } from '../../../common/components';

export default function Countdown({ onFinishCountdown, onStartCountdown, remoteStart, IAmHost, setEstimateLock }) {
	const [started, setStarted] = useState(remoteStart);
	const [counter, updateCounter] = useState(3);
	
	const onStart = () => {
		if (!remoteStart) {
			onStartCountdown();
		}
		
		setStarted(true);
		setTimeout(() => startCountdown(counter), 1000);
	};
	
	useEffect(() => {
		if (!remoteStart || started) { return; }
		
		onStart();
	}, [remoteStart, started])
	
	const startCountdown = (value) => {
		const newValue = value - 1;
		
		if (newValue === 0) {
			return void onFinishCountdown();
		}
		
		if (newValue === 1) {
			setEstimateLock(true);
		}
		
		updateCounter(newValue);
		
		setTimeout(() => startCountdown(newValue), 1000);
	};
	
	if (!started) {
		return (
			<div className="countdown">
				<Time />
				{
					IAmHost
						? (
							<div className="countdown__waiting">
								<p>Start a 3 second countdown, before showing everyone’s results.</p>
								<Button id="start-countdown-btn" onClick={onStart} id="start-countdown-btn">Start Countdown</Button>
							</div>
						)
						: (
							<Text>
								Waiting for the host to start the countdown. You can choose an estimate before the countdown starts.
							</Text>
						)
				}
			
			</div>
		);
	}
	
	return (
		<div className="countdown--counting">
			<h1>{counter}</h1>
		</div>
	)
}