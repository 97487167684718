import CopyToClipboard from 'react-copy-to-clipboard';
import Modal from './Modal';
import { Button, Input, Text } from '../../../common/components';
import '../styles/_share-modal.scss';
import { useState } from 'react';

const CODE = 'CODE';
const URL = 'URL';

export default function ShareModal({ meeting, onClose, visible }) {
	const [success, setSuccess] = useState(null);
	
	const onCopy = (type) => () => {
		setSuccess(type);
	};
	
	const onSuccessEnd = () => {
		setSuccess(null);
	};
	
	return (
		<Modal title="Share This Session" visible={visible} onClose={onClose}>
			<Text bold className="mb-10">Session Code</Text>
			<div className="share-modal__field">
				<Input disabled value={meeting?.id} />
				<CopyToClipboard text={meeting?.id} onCopy={onCopy(CODE)} >
					<Button id="copy-code-btn" icon="faCopy" state={success === CODE ? 'SUCCESS' : void 0} onStateEnd={onSuccessEnd} />
				</CopyToClipboard>
			</div>
			<Text bold className="mb-10">Session URL</Text>
			<div className="share-modal__field">
				<Input disabled value={window.location.href} />
				<CopyToClipboard text={window.location.href} onCopy={onCopy(URL)}>
					<Button id="copy-url-btn" icon="faCopy" state={success === URL ? 'SUCCESS' : void 0} onStateEnd={onSuccessEnd} />
				</CopyToClipboard>
			</div>
		</Modal>
	);
}