export default function getResultsFromMeeting({ members }) {
	const membersWithEstimates =  members.filter(member => Boolean(member.estimate));
	const estimateCounts = members.reduce((counts, member) => {
		const { estimate } = member;
		
		if (!estimate) { return counts; }
		
		const forArray = estimate - 1;
		const newCounts = [...counts];
		
		newCounts.splice(forArray, 1, counts[forArray] + 1);
		
		return newCounts;
	}, [...new Array(10)].map(() => 0));
	
	const isTiedCount = testCountIndex => estimateCounts.filter(count => count === estimateCounts[testCountIndex]).length > 1;
	
	const mostCommon = estimateCounts.reduce(({ highestIndex, tie }, count, index) => {
		if (index === 0) { return { highestIndex, tie }; }
		
		const higher = count > estimateCounts[highestIndex];
		
		if (!higher) { return { highestIndex, tie }; }
		
		return { highestIndex: index, tie: isTiedCount(index) };
	}, { highestIndex: 0, tie: isTiedCount(0) });
	
	const average = membersWithEstimates.length
		? Math.round(membersWithEstimates.reduce((total, member) => member.estimate + total, 0) / membersWithEstimates.length)
		: 'No estimates';
	
	return {
		average,
		mostCommon: mostCommon.tie ? 'None' : mostCommon.highestIndex + 1
	};
}