import RToggle from 'react-toggle';
import { useRef } from 'react';
import cx from 'classnames';
import './_toggle.scss';

export default function Toggle({ className, label = null, defaultChecked, onChange }) {
	const toggleRef = useRef(null);
	
	const onChangeHandler = () => {
		if (!toggleRef) { return; }
		
		onChange(toggleRef.current.input?.checked);
	};
	
	return (
		<label className={cx('tte-toggle', className)}>
			<RToggle icons={false} ref={toggleRef} defaultChecked={defaultChecked} onChange={onChangeHandler} />
			{label && <span className="tte-toggle__label">{label}</span>}
		</label>
	);
}
