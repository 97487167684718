import React from 'react';
import ReactDOM from 'react-dom';
import 'what-input';
import { BrowserRouter } from 'react-router-dom';
import { init as initSentry } from '@sentry/react';
import App from './App';
import './_index.scss';

if (process.env.NODE_ENV !== 'development') {
  initSentry({
    dsn: 'https://76d8f1faf2af443ba9d2757617b7aa42@o140099.ingest.sentry.io/5919886'
  });
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

