import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './_info-tooltip.scss';
import cx from 'classnames';

export default function InfoTooltip({ id, text, className }) {
	const onClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};
	
	return (
		<>
			<div className={cx('info-tooltip', className)} onClick={onClick} data-tip={id} data-for={id}>
				<FontAwesomeIcon icon={faInfoCircle} />
			</div>
			<ReactTooltip
				id={id}
				place="top"
				effect="solid"
				backgroundColor="#FFF"
				textColor="#264653"
				delayShow={300}
				multiline
				border
				borderColor="#E5E5E5"
			>
				{text}
			</ReactTooltip>
		</>
	)
}