import Select from 'react-select';
import Async from 'react-select/async';
import Modal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import Input from '../../../common/components/Input';
import { Button, Text } from '../../../common/components';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import '../styles/_task-modal.scss';
import RadioButtons from './RadioButtons';
import cx from 'classnames';
import Noty from 'noty';

const CUSTOM = 'CUSTOM';

const SELECT_STYLES = fieldError => ({
	control: (base, state) => ({
		...base,
		borderColor: fieldError ? '#FF453A' : (state.isFocused ? '#00A08F !important' : '#CBCCCC'),
		boxShadow: fieldError ? '0 0 0 1px #FF453A' : (state.isFocused ? '0 0 0 1px #00A08F' : void 0),
		height: '50px',
		color: 'red'
	}),
	singleValue: base => ({ ...base, color: '#264653' }),
	option: (base, { isSelected }) => ({
		...base,
		color: isSelected ? 'white' : '#264653',
		backgroundColor: isSelected ? '#00A08F' : void 0,
		':active': {
			...base[':active'],
			backgroundColor: 'red'
		},
		':hover': {
			...base[':hover'],
			backgroundColor: isSelected ? '#00A08F' : '#D3EBE8',
			color: isSelected ? 'white' : '#264653'
		},
	})
});

const taskOption = (site, task) => ({
	label: (
		<div className="task-modal__option">
				 <img
					 alt="jira-issue-icon"
					 className="task-modal__option__icon"
					 src={`https://${site.name}.atlassian.net${task.img}`}
				 />
				<span><strong>{task.key}</strong></span>
				<span>{task.summaryText}</span>
			</div>
	),
	value: task
});

function getOption({ label, value }) {
	if (value === void 0) { return { label }; }
	
	const parsed = parseInt(value, 10);
	
	if (isNaN(parsed)) { return { label, value: 'None', disabled: true }; }
	
	return { label, value: parsed };
}

function getInitialEstimateIndex(options) {
	return options.findIndex(option => !option.disabled);
}

function redirectToAtlassian(url) {
	window.location.href = url;
}

async function makeAtlassianRequest(url, token, authUrl) {
	if (!token) { return {}; }
	
	try {
		const result = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
		
		return result;
	} catch(e) {
		if (e.response && e.response.status === 401) {
			redirectToAtlassian(authUrl);
			
			return {};
		}
		
		new Noty({
			theme: 'metroui',
			text: 'Something went wrong getting information from Jira. Please try again.',
			type: 'error',
			timeout: 3000,
			layout: 'bottomRight'
		}).show();
		
		return {};
	}
}

export default function TaskModal({ visible, onClose, myEstimate = 0, results, authUrl, token }) {
	const selectedSite = localStorage.getItem('jira_site') && JSON.parse(localStorage.getItem('jira_site'));
	const selectedTask = localStorage.getItem('jira_task') && JSON.parse(localStorage.getItem('jira_task'));
	const spFieldId = localStorage.getItem('jira_field_id');
	
	const initialEstimateOptions = [
		getOption({ label: 'Average', value: results?.average }),
		getOption({ label: 'Most Common', value: results?.mostCommon }),
		getOption({ label: 'My Estimate', value: myEstimate }),
		getOption({ label: 'Other' })
	];
	const initialEstimateIndex = results ? getInitialEstimateIndex(initialEstimateOptions) : void 0;
	
	const [sites, setSites] = useState();
	const [site, setSite] = useState(selectedSite);
	const [fieldId, setFieldId] = useState(spFieldId);
	const [task, setTask] = useState(selectedTask ? taskOption(site, selectedTask): void 0);
	const [taskQuery, setTaskQuery] = useState();
	const [estimate, setEstimate] = useState(results ? initialEstimateOptions[initialEstimateIndex].value : void 0);
	const [customEstimate, setCustomEstimate] = useState(0);
	const [saveState, setSaveState] = useState(null);
	const [saving, setSaving] = useState(false);
	const [fieldError, setFieldError] = useState(false);
	
	useEffect(() => {
		setEstimate(results ? initialEstimateOptions[initialEstimateIndex].value : void 0);
		setCustomEstimate(0);
	}, [results]);
	
	useEffect(() => {
		localStorage.setItem('jira_site', !site ? '' : JSON.stringify(site));
	}, [site]);
	
	useEffect(() => {
		setFieldError(false);
		
		localStorage.setItem('jira_task', !task ? '' : JSON.stringify(task.value));
		
		if (!task) { return; }
		
		const getFields = async () => {
			const { data } = await makeAtlassianRequest(
				`https://api.atlassian.com/ex/jira/${site.value.id}/rest/api/3/issue/${task.value.id}/editmeta`,
				token,
				authUrl
			);
			
			if (!data) { return; }
			
			const matches = Object.values(data.fields).filter(item => item.name.includes('Story Points'));
			
			if (!matches.length) {
				return void setFieldError(true);
			}
			
			setFieldId(matches[0]?.key || '');
			
			localStorage.setItem('jira_field_id', matches[0]?.key || '');
		}
		
		getFields();
	}, [task]);
	
	useEffect(() => {
		const getSites = async () => {
			const { data } = await makeAtlassianRequest(
				'https://api.atlassian.com/oauth/token/accessible-resources',
				token,
				authUrl
			);
			
			if (!data) { return; }
			
			setSites(data);
		};
		
		getSites();
	}, [token]);
	
	const onStateEnd = () => {
		setSaveState(null);
	}
	
	const searchTasks = useCallback(async () => {
		const { data } = await makeAtlassianRequest(
			`https://api.atlassian.com/ex/jira/${site.value.id}/rest/api/3/issue/picker?query=${taskQuery}`,
			token,
			authUrl
		);
		
		if (!data) { return; }
		
		return ((data?.sections?.[0]?.issues) || []).map(task => taskOption(site, task));
	}, [taskQuery, site]);
	
	const onUpdateTaskEstimate = async () => {
		setSaving(true);
		
		try {
			await axios.put(
				`https://api.atlassian.com/ex/jira/${site.value.id}/rest/api/3/issue/${task.value.id}`,
				{
					fields: {
						[fieldId]: parseInt(estimate !== CUSTOM ? estimate : customEstimate, 10)
					}
				},
				{ headers: { Authorization: `Bearer ${token}` } }
			);
			
			setSaveState('SUCCESS');
		} catch(e) {
			if (e.response && e.response.status === 401) {
				window.location.href = authUrl;
				
				return {};
			}
			
			setSaveState('ERROR');
		} finally {
			setSaving(false);
		}
	};
	
	const handleUpdateCustomEstimate = ({ target: { value } }) => {
		setCustomEstimate(parseInt(value || 0, 10));
	}
	
	const body = document.querySelector('body');
	
	if (!token) {
		return (
			<Modal subtitle="Add your estimate to any Jira issue with a Story Points field." title="Add Estimate To Jira" visible={visible} onClose={onClose} width={700} height={750} className="task-modal">
				<Button className="task-modal__link-btn" onClick={() => { redirectToAtlassian(authUrl); }}>
					Link Jira
				</Button>
			</Modal>
		);
	}
	
	return (
		<Modal subtitle="Add your estimate to any Jira issue with a Story Points field." title="Add Estimate To Jira" visible={visible} onClose={onClose} width={700} height={750} className="task-modal">
			<div>
				<Text bold className="mb-10">Atlassian Site</Text>
				<Select
					defaultValue={site}
					isLoading={!sites}
					options={sites && sites.map(site => ({ label: site.name, value: site }))}
					onChange={setSite}
					styles={SELECT_STYLES()}
					menuPortalTarget={body}
				/>
			</div>
			{
				site && (
					<>
						<div>
							<Text bold className="mb-10">Task</Text>
							<Async
								defaultValue={task}
								cacheOptions
								loadOptions={searchTasks}
								onInputChange={setTaskQuery}
								onChange={setTask}
								styles={SELECT_STYLES(fieldError)}
								menuPortalTarget={body}
							/>
						</div>
						{fieldError && (
							<div className="task-modal__error">
								<FontAwesomeIcon icon={faExclamationTriangle} className="mr-20" size="2x" />
								<Text danger className="mt-0 mb-0">
									The <strong>Story Points field</strong> doesn't seem to be on this issue type's screen. <br /><br />Even if the field shows in the list of fields on this task, it still needs to be added to the issue screen.
									<a rel="noreferrer" style={{ marginLeft: '5px' }} target="_blank" href={`https://${site.value.name}.atlassian.net/jira/settings/issues/screens`}>
										Change Screen Schemes
									</a>
									.
								</Text>
							</div>
						)}
								<>
									<div>
										<Text bold className="mb-10">Estimate</Text>
										{
											results && (
												<RadioButtons
													onChange={setEstimate}
													options={initialEstimateOptions}
													initialIndex={initialEstimateIndex}
												/>
											)
										}
										{estimate === void 0 ? (
											<Input className={cx({ 'mt-20': results })} value={customEstimate} onChange={handleUpdateCustomEstimate} />
										): null}
									</div>
								</>
						<Button
							disabled={fieldError || !task}
							onClick={onUpdateTaskEstimate}
							onStateEnd={onStateEnd}
							state={saveState}
							busy={saving}
							id="save-task-estimate-btn"
						>
							Save
						</Button>
					</>
				)
			}
		</Modal>
	);
}
