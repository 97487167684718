import { formatDistanceToNow } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Text } from "../../../common/components";
import {useEffect, useRef, useState} from "react";
import {faBullhorn, faEdit, faEllipsisH, faTrash} from "@fortawesome/free-solid-svg-icons";
import { Menu, MenuItem } from "@szhsin/react-menu";
import Input from "../../../common/components/Input/Input";
import '../styles/_chat.scss';

function stringToHslColor(str, s, l) {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    return 'hsl('+hash % 360+', '+s+'%, '+l+'%)';
}

function Comment({
     comment: { uid: commentUid, name, text, timestamp, thread = [], id, announcement },
     uid,
     parentCommentId,
     onComment,
     onEdit,
     isMobile
}) {
    const initials = name.match(/\b(\w)/g).join('').toUpperCase().substr(0, 2);
    const [replying, setReplying] = useState(false);
    const [editing, setEditing] = useState(false);
    const [reply, setReply] = useState('');
    const [edit, setEdit] = useState(text);
    const replyField = useRef(null);
    const editField = useRef(null);

    const onChangeReply = ({ target: { value } }) => {
        setReply(value);
    };

    const toggleReply = () => {
        setReply('');

        if (replying) {
            return void setReplying(false);
        }

        enableReply();
    };

    const enableReply = () => {
        setReplying(true);

        setTimeout(() => {
            if (!replyField || !replyField.current) { return; }

            replyField.current.scrollIntoView({ behavior: 'smooth' });
            replyField.current.focus();
        }, 50);
    };

    const onSaveReply = () => {
        onComment({ text: reply, parentCommentId: parentCommentId || id });
        toggleReply();
    };

    const onSaveEdit = () => {
        onEdit({ commentId: id, text: edit });
        setEditing(false);
    }

    const onDelete = () => {
        onEdit({ commentId: id, text: '' });
    }

    const cancelEdit = () => {
        setEdit(text);
        setEditing(false);
    }

    const bgColour = stringToHslColor(name, 50, 80);

    if (announcement) {
        return (
            <div style={{ background: bgColour }} className="chat__announcement">
                <FontAwesomeIcon icon={faBullhorn} />
                <Text small bold>{text}</Text>
            </div>
        );
    }

    return (
        <div className="chat__comment">
            <div className="chat__comment__user">
                <span style={{ backgroundColor: bgColour }}>{initials}</span>
                <div className="chat__comment__user__separator" />
            </div>
            <div className="chat__comment__inline">
                <div className="chat__comment__right">
                    <div className="chat__comment__right__header">
                        <Text bold>{name}</Text>
                        {uid === commentUid && (
                            <Menu
                                offsetX={10}
                                direction="left"
                                align="center"
                                menuButton={(
                                    <button className="chat__comment__right__header__menu-btn">
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </button>
                                )}
                                transition
                            >
                                <MenuItem
                                    onClick={() => {
                                        setEditing(true);

                                        setTimeout(() => {
                                            if (!editField || !editField.current) { return; }

                                            editField.current.scrollIntoView({ behavior: 'smooth' });
                                            editField.current.focus();
                                        }, 50);
                                    }}
                                >
                                    <FontAwesomeIcon className="mr-20" icon={faEdit} />
                                    Edit
                                </MenuItem>
                                <MenuItem onClick={onDelete}>
                                    <FontAwesomeIcon style={{ color: "#FF453A" }} className="mr-20" icon={faTrash} />
                                    <Text danger>Delete</Text>
                                </MenuItem>
                            </Menu>
                        )}
                    </div>
                    {
                        editing ? (
                            <div className="chat__comment__right__edit">
                                <Input
                                    onChange={({ target: { value } }) => { setEdit(value); }}
                                    onContinue={onSaveEdit}
                                    continueDisabled={!edit}
                                    small={!isMobile}
                                    value={edit}
                                    onEscape={cancelEdit}
                                    onEnter={edit ? onSaveEdit : void 0}
                                    ref={editField}
                                />
                                <p className="mb-10 mt-5">
                                    {!isMobile ? 'Press Esc to ' : ''}
                                    <a onClick={cancelEdit}>{isMobile ? 'Cancel': 'cancel'}</a>
                                    .
                                </p>
                            </div>
                        )
                        : <Text>{text}</Text>
                    }
                    <div className="chat__comment__right__footer">
                        <Text small>{formatDistanceToNow(timestamp)}</Text>
                        {parentCommentId ? null : <a onClick={toggleReply}>Reply</a>}
                    </div>
                </div>
                {thread.map(comment => <Comment key={comment.id} isMobile={isMobile} onEdit={onEdit} uid={uid} comment={comment} parentCommentId={id} onComment={onComment} />)}
                {replying && (
                    <Input
                        small={!isMobile}
                        onContinue={onSaveReply}
                        continueDisabled={!reply}
                        ref={replyField}
                        value={reply}
                        placeholder="Write a reply"
                        onChange={onChangeReply}
                        onEnter={reply ? onSaveReply : void 0}
                    />
                )}
            </div>
        </div>
    );
}

export default function Chat({ meeting, onComment, onEdit, uid, isMobile }) {
    const [firstLoad, setFirstLoad] = useState(true);
    const [comment, setComment] = useState('');
    const [sortedComments, setSortedComments] = useState([]);
    const commentsRef = useRef(null);

    useEffect(() => {
        if (!meeting?.comments) { return; }

        const sorted = meeting.comments.reduce((sortedComments, comment) => {
            if (!comment.parentCommentId) {
                return [...sortedComments, comment];
            }

            const parentCommentIndex = sortedComments.findIndex(sortedComment => sortedComment.id === comment.parentCommentId);

            if (parentCommentIndex < 0) { return sortedComments; }

            const parentComment = sortedComments[parentCommentIndex];
            const newComment = { ...parentComment, thread: [...(parentComment.thread || []), comment] };
            const newComments = [...sortedComments];

            newComments.splice(parentCommentIndex, 1, newComment);

            return newComments;
        }, []);

        setSortedComments(sorted);
    }, [meeting?.comments])

    useEffect(() => {
        const lastComment = meeting?.comments?.[meeting.comments.length - 1];

        if (!commentsRef || !commentsRef.current || (!firstLoad && lastComment.parentCommentId)) { return; }

        commentsRef.current.scrollTo({ top: commentsRef.current.scrollHeight, behavior: 'smooth' });

        if (!firstLoad) { return; }

        setTimeout(() => { setFirstLoad(false); }, 50);
    }, [sortedComments, commentsRef, firstLoad]);

    const updateComment = ({ target: { value } }) => {
        setComment(value);
    };

    const onSaveComment = () => {
        onComment({ text: comment });
        setComment('');
    };

    const createComment = (
        <Input
            small={!isMobile}
            continueDisabled={!comment}
            onContinue={onSaveComment}
            placeholder="Leave a comment"
            onChange={updateComment}
            value={comment}
            onEnter={comment ? onSaveComment : void 0}
        />
    );

    if (!meeting) { return null; }

    if (!meeting.comments?.length) {
        return (
            <div className="chat--empty">
                <Text centered>There aren't any comments yet. Why don't you be the first to post one?</Text>
                {createComment}
            </div>
        );
    }

    return (
      <div className="chat">
          <div className="chat__comments-container">
              <div className="chat__comments" ref={commentsRef}>
                  {sortedComments.map(comment => <Comment key={comment.id} isMobile={isMobile} onEdit={onEdit} uid={uid} comment={comment} onComment={onComment} />)}
              </div>
          </div>
          {createComment}
      </div>
    );
}