import { useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './_tabs.scss';
import {withSize} from "react-sizeme";

const withSizeHOC = withSize({ monitorWidth: true, monitorHeight: false });

function Tabs({ children, tabs, size }) {
    const [tabIndex, setTabIndex] = useState(0);

    const onSelectTab = (index) => () => {
        setTabIndex(index);
    };

    const tabWidth = ((size?.width || 0) - 40) / tabs.length;
    const tabWidthPx = `${tabWidth}px`;

    return (
        <>
            <div className="tte-tabs--spacer" />
            <div className="tte-tabs">
                <div className="tte-tabs__header">
                    {tabs.map(({ text, icon }, index) => (
                        <button
                            key={text}
                            className={cx('tte-tabs__header__tab', { 'tte-tabs__header__tab--selected': index === tabIndex })}
                            onClick={onSelectTab(index)}
                            style={{ width: tabWidthPx }}
                        >
                            <FontAwesomeIcon icon={icon} />
                            <span>{text}</span>
                        </button>
                    ))}
                    <div className="tte-tabs__header__slider" style={{ left: tabIndex * tabWidth + 40, width: tabWidthPx }} />
                </div>
                <div className="tte-tabs__content">
                    {children.length ? children[tabIndex] : children}
                </div>
            </div>
        </>
    );
}

export default withSizeHOC(Tabs);
