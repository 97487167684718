import Button from '../../../common/components/Button';
import { useState } from 'react';
import { Text } from '../../../common/components';
import '../styles/_nudge-button.scss';

const TIMEOUT_S = 10;

export default function NudgeButton({ minimised, onNudge }) {
	const [clicked, setClicked] = useState(false);
	const [timeout, setCurrentTimeout] = useState(0);
	
	const updateTimeout = value => {
		setCurrentTimeout(value);
		
		if (value === 0) { return; }
		
		setTimeout(() => {
			updateTimeout(value - 1);
		}, 1000);
	}
	
	const onClick = () => {
		setClicked(true);
		onNudge();
	};
	
	const onStateEnd = () => {
		setClicked(false);
		updateTimeout(TIMEOUT_S);
	}
	
	if (minimised) {
		return (
			<div className="nudge-button--minimised">
				<Button
					id="nudge-mobile-btn"
					tooltipPlace="bottom"
					onClick={onClick}
					icon="faHandPointUp"
					tooltip="Nudge team mates who haven't estimated yet"
					tooltipId="nudge-team"
					disabled={timeout}
					state={clicked ? 'SUCCESS' : void 0}
					onStateEnd={onStateEnd}
				/>
				{timeout ? <Text className="nudge-button--minimised__timeout">{timeout}</Text> : null}
			</div>
		);
	}
	
	return (
		<Button
			fullWidth
			icon={!timeout ? 'faBell' : void 0}
			tooltip={<span>Nudge team mates<br />who haven't estimated yet</span>}
			tooltipPlace="top"
			tooltipId="nudge"
			onClick={onClick}
			disabled={timeout}
			state={clicked ? 'SUCCESS' : void 0}
			onStateEnd={onStateEnd}
			id="nudge-btn"
		>
			{timeout || 'Nudge'}
		</Button>
	);
}