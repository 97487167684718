import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCrown } from '@fortawesome/free-solid-svg-icons';
import NudgeButton from './NudgeButton';
import '../styles/_team.scss';
import { Text } from '../../../common/components';
import ReactTooltip from 'react-tooltip';

export default function Team({ members = [], host, onNudge, IAmHost, isMobile }) {
	const everyoneHasEstimated = members.every(({ estimate }) => Boolean(estimate));
	
	const content = (
		<>
			{/*<Text subHeading>Team ({members.length}){everyoneHasEstimated && <FontAwesomeIcon className="ml-20" icon={faCheck} color="#33C759" />}</Text>*/}
			<div className="team__members-container">
				<div className="team__members-container__members">
					{members.map(member => (
						<div className="team__member" key={member.uid}>
							<p>{member.name} {member.uid === host ? <FontAwesomeIcon icon={faCrown} data-tip="host-icon" data-for="host-icon" /> : null}</p>
							{member.estimate ? <FontAwesomeIcon icon={faCheck} color="#33C759" /> : null}
						</div>
					))}
				</div>
			</div>
			{IAmHost ? <NudgeButton onNudge={onNudge} /> : null}
		</>
	);
	
	if (isMobile) {
		return (
			<div className="team--mobile">
				<Text heading>Team ({members.length}){everyoneHasEstimated && <FontAwesomeIcon className="ml-20" icon={faCheck} color="#33C759" />}</Text>
				<div className="team__members-container mt-40">
					<div className="team__members-container__members">
						{members.map(member => (
							<div className="team__member" key={member.uid}>
								<p>{member.name} {member.uid === host ? <FontAwesomeIcon icon={faCrown} data-tip="host-icon" data-for="host-icon" /> : null}</p>
								{member.estimate ? <FontAwesomeIcon icon={faCheck} color="#33C759" /> : null}
							</div>
						))}
					</div>
				</div>
				{IAmHost ? <NudgeButton disabled={members.every(member => member.estimate)} onNudge={onNudge} /> : null}
			</div>
		)
	}
	
	return (
		<>
			<div className="team--spacer" />
			<div className="team">
				<ReactTooltip
					id="host-icon"
					place="left"
					effect="solid"
					backgroundColor="#FFF"
					textColor="#264653"
					delayShow={300}
					multiline
					border
					borderColor="#E5E5E5"
				>
					<span>Meeting host</span>
				</ReactTooltip>
				{content}
			</div>
		</>
	
	)
}