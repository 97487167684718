import ReactTooltip from 'react-tooltip';
import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import _debounce from 'lodash.debounce';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Text from '../Text';
import './_new-feature-wrapper.scss';

export default function NewFeatureWrapper({
  id,
  className,
  children,
  title,
  text,
  preferredPosition,
  tooltipProps = {}
}) {
    const triggerRef = useRef(null);
    const tooltipRef = useRef(null);
    const [show, setShow] = useState(!localStorage.getItem(id));

    const eventHandler = _debounce(() => {
        ReactTooltip.show(triggerRef?.current);
    }, 200);

    useEffect(() => {
        if (!show) { return; }

        window.addEventListener('resize', eventHandler);
        window.addEventListener('scroll', eventHandler);

        ReactTooltip.show(triggerRef?.current);

        return () => {
            window.removeEventListener('resize', eventHandler);
            window.removeEventListener('scroll', eventHandler);
        };
    }, [triggerRef?.current]);

    const hideTooltip = () => {
        localStorage.setItem(id, 'true');

        tooltipRef.current.tooltipRef = null
        ReactTooltip.hide(triggerRef?.current);

        window.removeEventListener('resize', eventHandler);

        setShow(false);
    };

    if (!show) { return children; }

    return (
        <>
            <div
                className={cx('new-feature-wrapper', className)}
                data-tip="tooltip"
                data-event="preventclick"
                ref={triggerRef}
            >
                {children}
            </div>
            <ReactTooltip
                effect="solid"
                backgroundColor="#264653"
                multiline
                clickable
                place={preferredPosition}
                delayShow={500}
                ref={tooltipRef}
                {...tooltipProps}
            >
               <div className="new-feature-wrapper__tooltip">
                   <div className="new-feature-wrapper__tooltip__header">
                       <Text bold>{title}</Text>
                       <FontAwesomeIcon icon={faTimes} onClick={hideTooltip} />
                   </div>
                   <Text small>{text}</Text>
               </div>
            </ReactTooltip>
        </>
    )
}