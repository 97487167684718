import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import * as ICONS from '@fortawesome/free-solid-svg-icons';
import * as BRAND_ICONS from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './_button.scss';
import { useEffect, useState } from 'react';

const BUSY_ICON = 'faCircleNotch';

export default function Button({
    type = 'primary',
    fullWidth,
    children,
    disabled,
    onClick,
    icon,
    busy,
    tooltip,
    tooltipId,
    tooltipPlace = 'right',
    className,
    onStateEnd,
    state,
    id
}) {
    const [showStateIcon, setButtonState] = useState(Boolean(state));
    const busyIcon = showStateIcon ? (state === 'SUCCESS' ? 'faCheck' : 'faTimes') : BUSY_ICON;
    const prefixIcon = busy || showStateIcon ? busyIcon : icon;
    
    useEffect(() => {
        if (state) {
            setButtonState(true);
            
            const timeout = setTimeout(() => {
                setButtonState(false);
                
                if (!onStateEnd) {
                    return;
                }
                
                onStateEnd();
            }, 2000);
            
            return () => {
                if (!timeout) {
                    return;
                }
                
                clearTimeout(timeout);
            };
        }
    }, [state]);
    
    return (
        <button
            disabled={busy || disabled || state}
            className={cx('tte-button', `tte-button--${type}`, { 'tte-button--full-width': fullWidth, 'tte-button--icon': icon && !children, 'tte-button--busy': busy || state }, className)}
            onClick={onClick}
            data-tip={tooltipId}
            data-for={tooltipId}
            id={id}
        >
            {prefixIcon ? <FontAwesomeIcon icon={ICONS[prefixIcon] || BRAND_ICONS[prefixIcon]} color="white" spin={prefixIcon === BUSY_ICON} /> : null}
            {children && <span style={{ opacity: busy || state ? 0 : 1}}>{children}</span>}
            {
                tooltip && !busy && !disabled && !state && (
                  <ReactTooltip
                    id={tooltipId}
                    place={tooltipPlace}
                    effect="solid"
                    backgroundColor="#FFF"
                    textColor="#264653"
                    delayShow={300}
                    multiline
                    border
                    borderColor="#E5E5E5"
                  >
                     {tooltip}
                  </ReactTooltip>
                )
            }
        </button>
    );
}