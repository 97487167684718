import { forwardRef } from 'react';
import cx from 'classnames';
import './_input.scss';
import Button from "../Button";

const ENTER_KEY_CODE = 'Enter';
const ESC_KEY_CODE = 'Escape';

function handleKeyDown(handlers) {
    return (e) => {
        if (!handlers[e.code]) { return; }

        handlers[e.code]();
    }
}

function Input({
    placeholder,
    disabled,
    onChange,
    multiline,
    value = '',
    id,
    hasTooltip,
    className,
    onBlur,
    onFocus,
    continueDisabled,
    onContinue,
    small,
    onEscape,
    onEnter
}, ref) {
    const Element = multiline ? 'textarea' : 'input';

    const input = (
        <Element
          disabled={disabled}
          className={cx('input', { 'input--small': small }, className)}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          data-tip={hasTooltip ? 'tooltip' : void 0}
          data-event="preventclick"
          ref={ref}
          id={id}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={
              onEscape || onEnter
                  ? handleKeyDown({
                      [ESC_KEY_CODE]: onEscape,
                      [ENTER_KEY_CODE]: onEnter
                  })
                  : void 0
          }
        />
    );

    if (!onContinue) { return input; }

    return (
        <div
            className={cx('input-continue', { 'input-continue--small': small })}
        >
            {input}
            <Button icon="faArrowRight" disabled={continueDisabled} onClick={continueDisabled ? void 0 : onContinue} />
        </div>
    )
}

export default forwardRef(Input);