import MeetingComponent from '../components/Meeting';
import { CoreLayoutContext } from '../../CoreLayout';

export default function Meeting(props) {
	return (
		<CoreLayoutContext.Consumer>
			{({ setTitle, setSecondaryContent, isMobile }) => (
				<MeetingComponent {...props} setTitle={setTitle} setSecondaryContent={setSecondaryContent} isMobile={isMobile} />
			)}
		</CoreLayoutContext.Consumer>
	)
}
