import Rodal from 'rodal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import { Text } from '../../../common/components';
import '../styles/_modal.scss';

export default function Modal({ children, title, visible, onClose, className, width = 500, subtitle }) {
	return (
		<Rodal
			visible={visible}
			onClose={onClose}
			className="tte-modal"
			closeOnEsc
			showCloseButton={false}
			width={width}
		>
			<div className="tte-modal__header">
				<div>
					<Text heading>{title}</Text>
					{subtitle ? <Text className="mt-10" bold>{subtitle}</Text> : null}
				</div>
				<FontAwesomeIcon icon={faTimes} onClick={onClose} />
			</div>
			<div className={cx('tte-modal__content', className)}>
				{children}
			</div>
		</Rodal>
	);
}