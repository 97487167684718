import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as LogoIcon } from '../assets/images/logo-icon.svg';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import { ReactComponent as Team } from '../assets/images/team.svg';
import { ReactComponent as AnnounceHero } from '../assets/images/announce-hero.svg';
import { ReactComponent as CountdownHero } from '../assets/images/countdown-hero.svg';
import { ReactComponent as HandsHero } from '../assets/images/hands-hero.svg';
import { ReactComponent as JiraHero } from '../assets/images/jira-hero.svg';
import Input from '../common/components/Input';
import Button from '../common/components/Button';
import './styles/_name.scss';
import { useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { faGithub, faJira, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faBullhorn, faChevronDown, faClock, faGlobe, faHandPaper } from '@fortawesome/free-solid-svg-icons';
import { Text } from '../common/components';
import postEvent from '../common/utils/postEvent';

const FEATURES = [
  { text: 'Add the results of your team’s estimates to Jira with a couple of clicks.', title: 'Jira Integration', icon: faJira, Image: JiraHero },
  { text: 'Kick off each estimate with a 3 second countdown. Your team mates will jump into action and choose their estimates when they see the countdown!', title: 'Countdowns', icon: faClock, Image: CountdownHero },
  { text: 'Send announcement messages to the whole team. You could let everyone know the task they’re estimating, for example.', title: 'Announcements', icon: faBullhorn, Image: AnnounceHero },
  { text: 'See your’s and your team mate’s hand and finger counts! This adds a fun visualisation to everyone’s estimates.', title: 'Hand Visualisations', icon: faHandPaper, Image: HandsHero }
];

function Feature({ text, title, icon, Image }) {
  return (
    <div className="name-page__features__feature">
      <Image />
      <div className="name-page__features__feature__content">
        <div className="name-page__features__feature__content__title">
          <FontAwesomeIcon icon={icon} />
          <Text subHeading>{title}</Text>
        </div>
        <Text>{text}</Text>
      </div>
    </div>
  )
}

export default function Name({ onContinue }) {
    const [name, setName] = useState('');
    
    const tooltipRef = useRef(null);

    const onChangeName = ({ target: { value } }) => {
        ReactTooltip.hide(tooltipRef?.current);
       
        const matchesMeetingCode = /(.+)-(.+)-(.+)/g.test(value);
    
        setName(value);
    
        if (!matchesMeetingCode) { return; }
        
        ReactTooltip.show(tooltipRef?.current);
    };

    const handleContinue = () => {
      postEvent('sign-up');
      
      onContinue(name);
    };
    
    const getStarted = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      document.querySelector('#name-input').focus({ preventScroll: true });
    };

    return (
        <div className="name-page">
            <ReactTooltip
              place="bottom"
              effect="solid"
              backgroundColor="#FFF"
              textColor="#264653"
              multiline
              border
              borderColor="#E5E5E5"
            >
                Hmm, this looks like a meeting code.
            </ReactTooltip>
            <div className="name-page__content">
              <div className="name-page__content__columns">
                <div className="name-page__content__left">
                  <div>
                    <Logo className="name-page__logo" />
                    <h1 className="name-page__hero">Remotely estimate tasks with your team.</h1>
                    <Text className="mt-40 name-page__hero-subtitle">
                      In a time where more teams are working remotely, make task estimation more fun, simple and engaging. <strong>Free forever, no sign up required</strong>.
                    </Text>
                  </div>
                    <Input
                      large
                      hasTooltip
                      ref={tooltipRef}
                      onChange={onChangeName}
                      placeholder="What's your name?"
                      value={name}
                      id="name-input"
                      onContinue={handleContinue}
                    />
                </div>
                <div className="name-page__content__right">
                  <Team />
                </div>
              </div>
              <FontAwesomeIcon icon={faChevronDown} size="2x" />
            </div>
            <div className="name-page__features">
              <div className="name-page__features__container">
                {FEATURES.map(feature => <Feature {...feature} />)}
              </div>
            </div>
            <div className="name-page__getting-started ml-auto mr-auto">
              <Text heading className="ml-auto mr-auto" centered>Get started with Time To Estimate</Text>
              <div className="name-page__getting-started__container ml-auto mr-auto">
                <div className="name-page__getting-started__block">
                  <div className="name-page__getting-started__block__number">1</div>
                  <Text bold>Basic information</Text>
                  <Text><a onClick={getStarted}>Tell us your name</a>. It's free for teams of any size, no sign up required. We don't store or send any information about you.</Text>
                </div>
                <div className="name-page__getting-started__block">
                  <div className="name-page__getting-started__block__number">2</div>
                  <Text bold>Create a session</Text>
                  <Text>Time To Estimate is better together (no, really, it’s a bit underwhelming by yourself), and it’s easy to invite your team with a simple session code.</Text>
                </div>
                <div className="name-page__getting-started__block">
                  <div className="name-page__getting-started__block__number">3</div>
                  <Text bold>Estimate tasks</Text>
                  <Text>Each person chooses their estimate. The host then starts the countdown before everyone sees the results of the team's estimation.</Text>
                </div>
              </div>
            </div>
            <div className="name-page__banner-container">
              <div className="name-page__banner">
                <div className="name-page__banner__get-started">
                  <Text heading>Make remote estimation more fun. No sign up required.</Text>
                  <Button onClick={getStarted}>
                    Get Started For Free
                  </Button>
                </div>
              </div>
            </div>
            <div className="name-page__footer">
              <LogoIcon />
              <div className="name-page__footer__content">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="name-page__footer__content__list" >
                    <a href="https://www.privacypolicies.com/generic/" target="_blank" rel="noreferrer">Privacy</a>
                    <a href="https://www.twitter.com/alexgurr" target="_blank" rel="noreferrer">Contact Us</a>
                  </div>
                  <div className="name-page__footer__content__list" >
                    <a href="https://www.twitter.com/alexgurr" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter} size="2x" /></a>
                    <a href="https://www.github.com/alexgurr" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faGithub} size="2x" /></a>
                    <a href="https://www.alexgurr.com" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faGlobe} size="2x" /></a>
                  </div>
                </div>
                <Text className="name-page__footer__copyright mt-40">©2021 Alex Gurr. All rights reserved. Various trademarks held by their respective owners.</Text>
              </div>
            </div>
        </div>
    );
}