import { ReactComponent as Package } from '../../../assets/images/results.svg';
import { Button, Text } from '../../../common/components';
import '../styles/_stats.scss';

function Stat({ title, value }) {
	return (
		<div className="stats__stat">
			<div className="stats__stat__text">
				<Text>{title}:</Text>
				<Text heading>{value}</Text>
			</div>
		</div>
	);
}

export default function Stats({ members, IAmHost, onResetCountdown, results: { mostCommon, average } }) {
	const membersWithEstimates = members.filter(member => Boolean(member.estimate));
	
	return (
		<div className="stats">
			<Package />
			<div className="stats__right">
				<div className="stats__right__text">
					<Stat title="Most Common" value={mostCommon} />
					<Stat title="Average" value={average} />
					<Stat title="Completion" value={`${Math.round((membersWithEstimates.length / members.length) * 100)}%`} />
				</div>
				{
					IAmHost
						? <Button onClick={onResetCountdown} id="estimate-again-btn" icon="faHistory">Estimate Again</Button>
						: <Text bold centered>Waiting for the host to restart.</Text>
				}
			</div>
		</div>
	);
}
