import Input from '../common/components/Input';
import Button from '../common/components/Button';
import { ReactComponent as JoinMeeting } from '../assets/images/join-meeting.svg';
import { ReactComponent as CreateMeeting } from '../assets/images/create-meeting.svg';
import { useRef, useState } from 'react';
import './styles/_landing.scss'
import { Text } from '../common/components';
import ReactTooltip from 'react-tooltip';

function LandingColumn({ Image, title, subtitle, children }) {
  return (
    <div className="landing__columns__column">
      <Image />
      <Text subHeading className="landing__columns__column__title mt-40">{title}</Text>
      <Text className="landing__columns__column__subtitle mb-40">{subtitle}</Text>
      <div className="landing__columns__column__child">
        {children}
      </div>
    </div>
  );
}

function isMeetingCode(code) {
  return /(.+)-(.+)-(.+)/g.test(code)
}

export default function Landing({ onCreateMeeting, onMeetingId }) {
    const [id, setId] = useState('');
    const tooltipRef = useRef(null);

    const onChangeId = ({ target: { value } }) => {
      setId(value);
    };

    const handleContinue = () => {
      if(!isMeetingCode(id)) { return; }
      
      onMeetingId(id);
    };
    
    const onBlur = () => {
      if(!id || isMeetingCode(id)) { return; }
  
      ReactTooltip.show(tooltipRef?.current);
    }
    
    const onFocus = () => {
      ReactTooltip.hide(tooltipRef?.current);
    }

    return (
        <div>
          <div className="landing__columns">
            <LandingColumn
              Image={JoinMeeting}
              title="Join An Existing Session"
              subtitle="Someone else is hosting the session. They’ve shared their session's invite code with you and asked you to join."
            >
                <Input
                  onContinue={handleContinue}
                  continueDisabled={!id}
                  placeholder="Enter a session code"
                  onChange={onChangeId}
                  value={id}
                  ref={tooltipRef}
                  onBlur={onBlur}
                  hasTooltip
                  onFocus={onFocus}
                  id="session-code-input"
                />
              <ReactTooltip
                place="bottom"
                effect="solid"
                backgroundColor="#FFF"
                textColor="#264653"
                multiline
                border
                borderColor="#E5E5E5"
              >
                <strong style={{ display: 'block' }}>Hmm, this doesn't look like a session invite code.</strong><br/>Anyone in the team can get the code using the share button in the session.
              </ReactTooltip>
            </LandingColumn>
            <span className="landing__columns__divider" />
            <LandingColumn
              Image={CreateMeeting}
              title="Create A New Session"
              subtitle="You are going to host the session. We'll give you a session code you can share with your team."
            >
              <Button id="create-session-btn" onClick={onCreateMeeting} fullWidth>Create Session</Button>
            </LandingColumn>
          </div>
        </div>
    );
}