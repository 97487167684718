import cx from 'classnames';
import { useState } from 'react';
import { Text } from '../../../../common/components';
import './_radio-buttons.scss';

export default function RadioButtons({ options, onChange, initialIndex = 0 }) {
	const [selectedIndex, setIndex] = useState(initialIndex);
	
	const onSelectButton = (index, value) => () => {
		if (selectedIndex === index) { return; }
		
		setIndex(index);
		onChange(value);
	};
	
	return (
		<div>
			<div className="radio-buttons">
				{options.map((option, index) => option ? (
					<button
						className={
							cx(
								'radio-buttons__option',
								{
									'radio-buttons__option--selected': selectedIndex === index,
									'radio-buttons__option--disabled': option.disabled,
								}
							)
						}
						onClick={onSelectButton(index, option.value)}
					>
						<Text bold className={cx('radio-buttons__option__label', { 'mb-10': option.value !== void 0 })}>{option.label}</Text>
						<Text heading>{option.value}</Text>
					</button>
				) : null)}
			</div>
		</div>
	);
}
