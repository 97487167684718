import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Carousel from '@brainhubeu/react-carousel';
import { useState } from 'react';
import cx from 'classnames';
import '@brainhubeu/react-carousel/lib/style.css'
import Hands from './Hands';
import '../styles/_results.scss';
import { Text } from '../../../common/components';

function MobileResultSlide({ members }) {
	return (
		<div className="results--mobile__page">
			{members.map(member => (
				<div className="results--mobile__page__member">
					{member.estimate
						? <Hands value={member.estimate} small aspectRatio="xMidYMid meet" />
						: <FontAwesomeIcon icon={faTimes} className="results__members__member__no-estimate" />
					}
					<Text className="ml-20 mr-20" subHeading ellipsis>{member.name}</Text>
					<Text heading className="ml-auto">{member.estimate}</Text>
				</div>
			))}
		</div>
	);
}

export default function Results({ members, isMobile }) {
	const [slide, setSlide] = useState(0);
	
	if (isMobile) {
		const slides = members.reduce((slides, member) => {
			const latestIndex = slides.length - 1;
			
			if (slides[latestIndex] && slides[latestIndex].length === 3) {
				return [...slides, [member]];
			}
			
			return [...slides.slice(0, latestIndex), [...slides[latestIndex], member]];
		}, [[]]);
		
		return (
			<div className="results--mobile">
				<Text subHeading className="ml-20 mb-20">Results</Text>
				{
					slides.length > 1 ? (
						<>
							<Carousel
								value={slide}
								plugins={[
									'centered'
								]}
								onChange={setSlide}
								offset={-40}
							>
								{slides.map((members, index) => <MobileResultSlide key={index} members={members} />)}
							</Carousel>
							<div className="results--mobile__dots">
								{slides.map((_, index) => (
									<button
										className={cx('results--mobile__dots__dot', { 'results--mobile__dots__dot--selected': index === slide })}
										onClick={index !== slide ? () => setSlide(index) : void 0}
									/>
								))}
							</div>
						</>
					) : <MobileResultSlide members={slides[0]} />
				}
			</div>
		);
	}
	
	return (
		<div className="results">
			<Text subHeading>Results</Text>
			<div className="results__members">
				{members.map(member => (
					<div className="results__members__member" style={{ position: 'relative' }} key={member.uid}>
						{member.estimate
							? <Hands value={member.estimate} small />
							: <FontAwesomeIcon icon={faTimes} className="results__members__member__no-estimate" />
						}
						<Text className="mt-20" subHeading>{member.name}</Text>
						<Text heading className="mt-10">{member.estimate}</Text>
					</div>
				))}
			</div>
		</div>
	)
}