import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {useEffect, useRef} from 'react';
import RActionSheet from 'actionsheet-react';
import './_actionsheet.scss';

const BG_STYLE = {
    background: 'rgba(0, 0, 0, 0.3)'
};

const SHEET_STYLE = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '50vh',
    maxHeight: '85vh',
    height: '100%'
};

export default function ActionSheet({ children, onOpenAvailable }) {
    const sheetRef = useRef();

    useEffect(() => {
        onOpenAvailable(() => sheetRef?.current?.open);
    }, [])

    return (
        <RActionSheet
            ref={sheetRef}
            bgStyle={BG_STYLE}
            sheetStyle={SHEET_STYLE}
            touchEnable={false}
            mouseEnable={false}
            // https://github.com/mohit23x/actionsheet-react/issues/66
            closeOnBgTap
        >
            <div className="actionsheet">
                <div className="actionsheet__header">
                    <span className="actionsheet__header__handle" />
                    <FontAwesomeIcon
                        className="actionsheet__header__close"
                        icon={faTimes}
                        onClick={sheetRef?.current?.close}
                        size="2x"
                    />
                </div>
                <div className="actionsheet__content">
                    {children}
                </div>
            </div>
        </RActionSheet>
    );
}
