import { useEffect, useRef, useState } from 'react';
import Noty from 'noty';
import io from 'socket.io-client';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import queryString from 'querystring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import webNotification from 'simple-web-notification';
import cx from 'classnames';
import {faCommentAlt, faComments, faTimes, faUsers} from '@fortawesome/free-solid-svg-icons';
import 'rodal/lib/rodal.css';
import CONFIG from '../../../config';
import Actions from './Actions';
import Results from './Results';
import Countdown from './Countdown';
import Estimate from './Estimate';
import Team from './Team';
import notificationIcon from '../../../assets/images/notification.png';
import AnnouncementModal from './AnnouncementModal';
import TaskModal from './TaskModal';
import getResultsFromMeeting from '../utils/getResultsFromMeeting';
import Stats from './Stats';
import ShareModal from './ShareModal';
import Error from './Error';
import '../styles/_meeting.scss';
import {ActionSheet, Tabs} from "../../../common/components";
import Chat from "./Chat";
import NewFeatureWrapper from "../../../common/components/NewFeatureWrapper";

let socket;

export default function Meeting({ meeting: createdMeeting, name, uid, isMobile, setTitle, setSecondaryContent }) {
    const { meetingId } = useParams();
    const [visibleModal, setModalVisible] = useState(null);
    const [estimateLocked, setEstimateLock] = useState(false);
    const [announcement, setAnnouncement] = useState(null);
    const [meeting, setMeeting] = useState(createdMeeting);
    const [myEstimate, setEstimate] = useState(null);
    const [showResults, setDisplayResults] = useState(false);
    const [remoteStart, setRemoteStart] = useState(false);
    const [error, setError] = useState();
    const [jiraToken, setJiraToken] = useState(null);
    const [countdownStarted, setCountdownStarted] = useState(false);
    const [onOpenTeam, setOpenTeam] = useState();
    const [onOpenChat, setOpenChat] = useState();

    const [jiraCode] = useState(queryString.parse(window.location.search)['?jira_code']);
    const hasConnectedJira = Boolean(localStorage.getItem('jira_refresh_token')) || jiraCode;
    
    const IAmHost = uid === meeting?.host;
    const results = showResults ? getResultsFromMeeting(meeting) : null;
    const history = useHistory();
    
    const authUrl = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=ulj4mHoIjTXfty7cbkZIGq56w3Cmavqw&scope=read:jira-work%20write:jira-work%20offline_access&redirect_uri=https%3A%2F%2Fwww.timetoestimate.com&&state=${meetingId}&response_type=code&prompt=consent`;
    
    useEffect(() => {
        if (!meeting) { return; }

        setTitle(meeting.name);

        setSecondaryContent(
          <Actions
            onShare={meeting && showModal('SHARE')}
            onAnnounce={showModal('ANNOUNCE')}
            onTask={!jiraToken && hasConnectedJira ? void 0 : showModal('TASK')}
            IAmHost={IAmHost}
            onNudge={onNudge}
            onOpenTeam={onOpenTeam}
            onOpenChat={onOpenChat}
            members={meeting?.members}
            isMobile={isMobile}
          />
        )
    }, [meeting, jiraToken, onOpenChat, onOpenTeam, isMobile, hasConnectedJira]);
    
    useEffect(() => {
        socket = io(CONFIG.apiUrl, { transports: ['websocket', 'polling', 'flashsocket'] });

        window.onbeforeunload = () => {
            socket.emit('LEAVE_MEETING', JSON.stringify({ meetingId, uid }));
        };

        socket.on('ERROR', message => {
            if (!meeting) {
                return void setError(message === 'Meeting not found' ? 404 : 500);
            }
            
            new Noty({
                theme: 'metroui',
                text: 'Oops, something went wrong. Try refreshing the page.',
                type: 'error',
                timeout: 3000,
                layout: 'bottomRight'
            }).show();
        });

        socket.emit('JOIN_MEETING', JSON.stringify({ meetingId, name, uid }));
        
        if (hasConnectedJira && !jiraCode) {
            const refreshToken = async () => {
                try {
                    const { data: { access_token, refresh_token } } = await axios.post(
                      'https://auth.atlassian.com/oauth/token',
                      {
                          grant_type: 'refresh_token',
                          client_id: 'ulj4mHoIjTXfty7cbkZIGq56w3Cmavqw',
                          client_secret: 'r1kEXbV3jlN9D-5EpLd-fqrxWLajMAqAUoiVahemGkzlAlFnNYuiKba_qVVEmbI4',
                          refresh_token: localStorage.getItem('jira_refresh_token')
                      }
                    );
    
                    setJiraToken(access_token);
                    localStorage.setItem('jira_refresh_token', refresh_token);
                } catch(e) {
                    localStorage.setItem('jira_refresh_token', '');
    
                    setJiraToken(null);
                }
            };

            refreshToken();
        }
        
        if (jiraCode) {
            const swapJiraCodeForToken = async () => {
                history.push(`/meeting/${meetingId}`);
                
                try {
                    const { data: { access_token, refresh_token } } = await axios.post(
                      'https://auth.atlassian.com/oauth/token',
                      {
                          grant_type: 'authorization_code',
                          client_id: 'ulj4mHoIjTXfty7cbkZIGq56w3Cmavqw',
                          client_secret: 'r1kEXbV3jlN9D-5EpLd-fqrxWLajMAqAUoiVahemGkzlAlFnNYuiKba_qVVEmbI4',
                          code: jiraCode,
                          redirect_uri: 'https://www.timetoestimate.com'
                      }
                    );
    
                    new Noty({
                        theme: 'metroui',
                        text: 'Time To Estimate has been linked with Jira successfully. You can now add your estimates to tasks.',
                        type: 'success',
                        timeout: 3000,
                        layout: 'bottomRight'
                    }).show();
    
                    setJiraToken(access_token);
                    localStorage.setItem('jira_refresh_token', refresh_token);
                } catch(e) {
                    new Noty({
                        theme: 'metroui',
                        text: 'Something went wrong connecting to Jira. Please try again.',
                        type: 'error',
                        timeout: 3000,
                        layout: 'bottomRight'
                    }).show();
                    
                    setJiraToken(null);
                    localStorage.setItem('jira_refresh_token', '');
                }
            };
            
            swapJiraCodeForToken();
        }
    
        return () => {
            socket.emit('LEAVE_MEETING', JSON.stringify({ meetingId, uid }));
        };
    }, []);

    const onChangeEstimate = (value) => {
        setEstimate(value);

        socket.emit('ESTIMATE', JSON.stringify({ meetingId: meeting.id, uid, estimate: value }));
    }

    const onCountDownEnd = () => {
        setCountdownStarted(false);
        setRemoteStart(false);
        setDisplayResults(true);
    }

    const onResetCountdown = () => {
        setEstimateLock(false);
        setDisplayResults(false);
        setEstimate(0);
        
        if (!IAmHost) { return; }

        socket.emit('RESET_COUNTDOWN', meeting.id);
    }
    
    const onNudge = () => {
        socket.emit('NUDGE', meeting.id);
    }
    
    const onSendAnnouncement = (message) => {
        socket.emit('ANNOUNCEMENT', JSON.stringify({ meetingId: meeting.id, message, uid }));
    }

    useEffect(() => {
        if (!socket) { return; }

        // Remove socket callbacks
        socket.off('JOIN_MEETING');
        socket.off('MEETING_UPDATED');
        socket.off('START_COUNTDOWN');
        socket.off('NUDGE');
        socket.off('ANNOUNCEMENT');
        
        
        // Add socket callbacks
        socket.on('MEETING_UPDATED', handleUpdatedMeeting);
        socket.on('START_COUNTDOWN', onRemoteCountdownStart);
        socket.on('NUDGE', handleNudge);
        socket.on('ANNOUNCEMENT', handleAnnouncement);

        if (IAmHost) { return; }
        
        socket.off('RESET_COUNTDOWN');
        socket.on('RESET_COUNTDOWN', onResetCountdown);
    }, [meeting, socket]);

    const handleUpdatedMeeting = (updatedMeeting) => {
        setMeeting(updatedMeeting);
    
        if (updatedMeeting.status !== 'RESULTS' || showResults || remoteStart || countdownStarted) {
            return;
        }
        
        setDisplayResults(true)
        setRemoteStart(false);
    }
    
    const onStartCountdown = () => {
        setCountdownStarted(true);
        
        socket.emit('START_COUNTDOWN', meeting.id);
    }

    const onRemoteCountdownStart = () => {
        if (IAmHost) { return; }

        setRemoteStart(true);
    }
    
    const handleNudge = () => {
        if (myEstimate) { return; }
        
        webNotification.showNotification('You\'ve Been Nudged', {
            body: 'It\'s time to estimate this task.',
            icon: notificationIcon,
            autoClose: 4000
        });
    };
    
    const handleAnnouncement = (message) => {
       setAnnouncement(message);
       setModalVisible('ANNOUNCE');
    };

    const showModal = (type) => () => {
        setModalVisible(type);
    };
    
    const hideModal = () => {
        setModalVisible(null);
    };

    const onComment = ({ text, parentCommentId }) => {
        socket.emit('COMMENT', JSON.stringify({ meetingId: meeting.id, text, uid, parentCommentId }));
    }

    const onEdit = ({ text, commentId }) => {
        socket.emit('EDIT_COMMENT', JSON.stringify({ meetingId: meeting.id, text, commentId }));
    }

    return (
        <>
            {error && <Error notFound={error === 404} />}
            {
                meeting ? (
                    <>
                        <div className="meeting__content">
                            <div className={cx('meeting__content__main', { 'meeting__content__main--results': showResults })}>
                                {
                                    showResults
                                      ? <Results members={meeting?.members} isMobile={isMobile} />
                                      : (
                                        <Countdown
                                          IAmHost={IAmHost}
                                          onFinishCountdown={onCountDownEnd}
                                          onStartCountdown={onStartCountdown}
                                          remoteStart={remoteStart}
                                          setEstimateLock={setEstimateLock}
                                        />
                                      )
                                }
                            </div>
                            {
                                showResults
                                    ? <Stats members={meeting?.members} results={results} IAmHost={IAmHost} onResetCountdown={onResetCountdown} />
                                    : (
                                      <Estimate
                                        useInfinite={meeting?.useInfinite}
                                        useFibonacci={meeting?.useFibonacci}
                                        estimate={myEstimate}
                                        onChangeEstimate={onChangeEstimate}
                                        locked={estimateLocked}
                                      />
                                    )
                            }
                        </div>
                        {
                            !isMobile
                                ? (
                                    <NewFeatureWrapper
                                        id="team-comments"
                                        title="Introducing Team Comments"
                                        text="Have conversations with your team mates in the session. Leave replies to specific comments and build comment threads. Hosts can still send announcements to the whole team."
                                        preferredPosition="left"
                                    >
                                        <Tabs
                                            tabs={[
                                                {
                                                    text: `Team (${(meeting?.members && meeting?.members.length) || 1})`,
                                                    icon: faUsers
                                                },
                                                {
                                                    text: 'Comments',
                                                    icon: faCommentAlt
                                                }
                                            ]}
                                        >
                                            <Team
                                                members={meeting?.members}
                                                host={meeting?.host}
                                                onNudge={onNudge}
                                                IAmHost={IAmHost}
                                            />
                                            <Chat
                                                onComment={onComment}
                                                onEdit={onEdit}
                                                meeting={meeting}
                                                uid={uid}
                                            />
                                        </Tabs>
                                    </NewFeatureWrapper>

                                )
                                : null
                        }
                    </>
                )
                : null
            }
            <ShareModal meeting={meeting} onClose={hideModal} visible={visibleModal === 'SHARE'} />
            <AnnouncementModal
              visible={visibleModal === 'ANNOUNCE'}
              onClose={hideModal}
              onSave={onSendAnnouncement}
              announcement={announcement}
            />
            <TaskModal
              visible={visibleModal === 'TASK'}
              myEstimate={myEstimate}
              results={results}
              onClose={hideModal}
              authUrl={authUrl}
              token={jiraToken}
            />
            {isMobile && (
              <ActionSheet onOpenAvailable={setOpenTeam}>
                  <Team members={meeting?.members} host={meeting?.host} onNudge={onNudge} IAmHost={IAmHost} isMobile />
              </ActionSheet>
            )}
            {isMobile && (
                <ActionSheet onOpenAvailable={setOpenChat}>
                    <Chat
                        onComment={onComment}
                        onEdit={onEdit}
                        meeting={meeting}
                        uid={uid}
                        isMobile
                    />
                </ActionSheet>
            )}
        </>
    );
}
