import cx from 'classnames';
import { withSize } from 'react-sizeme';
import { ReactComponent as LogoIcon } from "../assets/images/logo-icon.svg";
import Button from '../common/components/Button';
import './styles/_core-layout.scss';
import { Text } from '../common/components';
import { createContext, useEffect, useState } from 'react';
import NewFeatureWrapper from "../common/components/NewFeatureWrapper";

const withSizeHOC = withSize({ monitorWidth: true, monitorHeight: false });

export const CoreLayoutContext = createContext({});

function CoreLayout({
    title,
    onBack,
    children,
    className,
    size = {},
    headerNewFeatureProps,
    headerNewFeatureMobileOnly
}) {
    const [titleOverride, setTitle] = useState(title);
    const [secondaryContent, setSecondaryContent] = useState(null);

    const isMobile = size.width <= 700;
    const logoVisible = !onBack || !isMobile;

    useEffect(() => {
      setTitle(void 0);
      setSecondaryContent(null);
    }, [title]);

    const header = (
        <div className="core-layout__header">
            {logoVisible && <LogoIcon />}
            {
                onBack
                    ? <Button id="back-btn" className={cx('core-layout__header__back', { 'ml-60': logoVisible })} icon="faArrowLeft" onClick={onBack} />
                    : null
            }
            {titleOverride || title ? <Text heading className={cx({ 'ml-20': logoVisible || onBack, 'ml-60': !isMobile && !onBack })}>{titleOverride || title}</Text> : null}
            <div className="core-layout__header__right">
                {secondaryContent}
            </div>
        </div>
    );

    return (
        <div className="core-layout">
            {headerNewFeatureProps && (!headerNewFeatureMobileOnly || (headerNewFeatureMobileOnly && isMobile)) ? (
                <NewFeatureWrapper
                    preferredPosition="bottom"
                    tooltipProps={isMobile ? { offset: { bottom: -20 } } : void 0}
                    {...headerNewFeatureProps}
                >
                    {header}
                </NewFeatureWrapper>
            ) : header}
            <div className={cx('core-layout__content', className)}>
              <CoreLayoutContext.Provider value={{ setSecondaryContent, setTitle, isMobile }}>
                {children}
              </CoreLayoutContext.Provider>
            </div>
        </div>
    );
}

export default withSizeHOC(CoreLayout);
